import React from 'react';
import styled from 'styled-components'
import breakpoint from 'styled-components-breakpoint';
import { Link } from 'gatsby'
import {
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon
} from 'react-share'

import Button from 'components/global/Button';

const StyledBtnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 0;
  align-items: center;
  width: 100%;
  ${breakpoint('sm')`
    flex-direction: row;
    justify-content: space-between;
  `}
`
const StyledShareBtnsContainer = styled.div`
  display: flex;
  margin-bottom: 30px;
  & > * {
    margin-right: 20px;
  }
  ${breakpoint('sm')`
    margin-bottom: 0;
  `}
`

const LinksRender = ({ articleType, articleUrl }) => {

  return (
    <StyledBtnsContainer>
      <StyledShareBtnsContainer>
        <LinkedinShareButton url={articleUrl}>
          <LinkedinIcon size={42} round={true} />
        </LinkedinShareButton>
        <FacebookShareButton url={articleUrl}>
          <FacebookIcon size={42} round={true} />
        </FacebookShareButton>
        <TwitterShareButton url={articleUrl}>
          <TwitterIcon size={42} round={true} />
        </TwitterShareButton>
      </StyledShareBtnsContainer>
      {articleType === 'Projet' ?
        <Link to="/projets">
          <Button>Voir tous nos projets</Button>
        </Link>
        : articleType === 'Actu' ?
          <Link to="/actus">
            <Button>Voir toutes nos actus</Button>
          </Link>
          : null}
    </StyledBtnsContainer>
  )
}

export default LinksRender
