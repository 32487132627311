import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Seo from "components/global/Seo";
import Layout from "components/global/Layout";
import Container from "components/global/Container";
import Heading from "components/global/Heading";
import Tag from "components/global/Tag";
import LinksRender from "./LinksRender";

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      slug
      title
      heroImage {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          width: 1290
          height: 800
        )
      }
      date(formatString: "Do MMMM YYYY", locale: "fr")
      tags
      accroche {
        accroche
      }
      description {
        childMarkdownRemark {
          html
        }
      }
      type
    }
  }
`;

const StyledGatsbyImage = styled(GatsbyImage)`
  img {
    border-radius: 20px;
  }
`;
const StyledDate = styled.p`
  color: ${(props) => props.theme.colors.greyDark};
  letter-spacing: 0.08em;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
const StyleContentContainer = styled.div`
  text-align: center;
  margin: 80px auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 700px;
`;
const StyledTagsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 400px;
`;
const StyledTeaser = styled.p`
  font-weight: 700;
  font-size: 20px;
  margin: 60px 0;
  max-width: 600px;
  letter-spacing: 0.2px;
  line-height: 27px;
`;
const StyledDescription = styled.div`
  text-align: left;
  ul,
  ol,
  p,
  a {
    font-size: 20px;
    margin-bottom: 30px;
    line-height: 32px;
  }
  ul {
    li {
      list-style: initial;
      margin-left: 30px;
    }
  }
  a {
    text-decoration: underline;
  }
  img {
    max-width: 100%;
  }
`;
const ArticleTemplate = ({ data, location }) => {
  const article = data.contentfulArticle;
  const { date, title, tags, accroche, description, type, heroImage } = article;
  const image = getImage(heroImage);

  const tagsRender = tags.map((tag) => {
    return <Tag key={tag}>{tag}</Tag>;
  });

  return (
    <>
      <Seo
        pageTitle={title}
        articleDescription={accroche.accroche}
        imageUrl={image.images.fallback.src}
      />
      <Layout>
        <Container>
          <StyledGatsbyImage image={image} alt={title} />
          <StyleContentContainer>
            <StyledDate>{date}</StyledDate>
            <Heading>{title}</Heading>
            <StyledTagsContainer>{tagsRender}</StyledTagsContainer>
            <StyledTeaser>{accroche.accroche}</StyledTeaser>
            <StyledDescription
              dangerouslySetInnerHTML={{
                __html: description.childMarkdownRemark.html,
              }}
            />
            <LinksRender articleType={type} articleUrl={location.href} />
          </StyleContentContainer>
        </Container>
      </Layout>
    </>
  );
};

export default ArticleTemplate;
